<template>
  <button class="hotel-in-catalog" @click="openWebsite">
    <img :src="`https://admin.moshot.ru/api/images_mini_hotels/${hotel_id}/${image}`" alt="Hotel Image">
    <div class="hotel-stars-catalog">
      <img v-for="n in stars" :key="n" src="../assets/img/star.png" alt="Star">
    </div>
    <p> {{ name }}</p>
  </button>
</template>

<script>
export default {
  props: {
    image: String,
    stars: Number,
    name: String,
    hotel_id: Number
  },
  methods: {
    openWebsite() {
      this.$router.push('/hotels/'+this.$props.hotel_id).then(() => {
        window.scrollTo(0, 0);
      });
    }
  }
}
</script>