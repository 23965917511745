<template>
    <div class="reserved-hotel">
        <div class="hotel-image">
            <img :src="getImageSrc(image)" alt="Hotel Image">
        </div>
        <div class="hotel-info">
            <div class="hotel-description">
                <p class="hotel-name"> {{ name }} </p>
                <p> {{ beds }} <span v-if="beds === 1"> большая кровать </span> <span v-if="beds !== 1 && beds < 5">
                        большие кровати </span> <span v-if="beds >= 5"> больших кроватей </span> </p>
            </div>
            <div class="hotel-service">
                <div class="hotel-service-pay">
                    <img src="../assets/img/card.png" alt="Payment">
                    <p>Оплата {{ canPay }} </p>
                </div>
                <div class="hotel-service-eat">
                    <img src="../assets/img/eat.png" alt="Breakfast">
                    <p>Завтрак <span v-if="eatAvailability === false"> не </span> включен </p>
                </div>
                <div class="hotel-service-wifi">
                    <img src="../assets/img/wifi.png" alt="Wi-Fi">
                    <p><span v-if="wifiAviability === true"> Бесплатный </span> <span v-if="wifiAviability === false">
                            Не бесплатный </span> Wi-Fi</p>
                </div>
            </div>
        </div>
        <div class="hotel-price-and-date">
            <div class="reserve-date">
                <p>Дата бронирования:</p>
                <p> {{ dateCheckIn }}–{{ dateCheckOut }}</p>
            </div>
            <div class="hotel-price">
                <p> {{ price }} </p>
            </div>
            <div class="hotel-sleep">
                <p> {{ nights }} <span v-if="nights === 1">ночь</span><span v-if="nights < 5 && nights !== 1">
                        ночи</span><span v-if="nights >= 5"> ночей</span>, {{ guests }} <span v-if="guests === 1"> гость
                    </span> <span v-if="guests < 5 && guests !== 1"> гостя </span> <span v-if="guests >= 5"> гостей
                    </span></p>
            </div>
        </div>
        <div class="reserved-true" v-if="reserved === true">
            <p>Активный</p>
        </div>
        <div class="reserved-false" v-if="reserved === false">
            <p>Закрытый</p>
        </div>
        <div class="reserved-in-process" v-if="reserved === null">
            <p>В обработке</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        reserved: Boolean,
        image: String,
        name: String,
        beds: Number,
        canPay: String,
        eatAvailability: Boolean,
        wifiAviability: Boolean,
        price: String,
        nights: Number,
        guests: Number,
        dateCheckIn: String,
        dateCheckOut: String,

    },
    methods: {
        getImageSrc(image) {
            try {
                return require(`@/assets/img/${image}`);
            } catch (error) {
                throw new Error(error);
            }
        }
    }
}
</script>