<template>
    <div class="header-menu-main" >
        <div class="menu-components">
            <div class="company-logo">
                <a href="/"><img src="../assets/img/company-logo-grey.png" alt=""></a>
            </div>
            <div class="header-anchors">
                <a href="/">О нас</a>
                <a href='/hotels'>Отели и номера</a>
                <a href="#contacts">Контакты</a>
                <a href="#" onclick="alert('Скоро появится на сайте!')">Авиабилеты</a>
            </div>
            <div class="wrapper" >
                <div class="head">
                    <div class="container">
                        <div class="header__body">
                            <div class="header__logo">
                                <a href="/"><img src="../assets/img/company-logo-grey.png" alt=""></a>
                            </div>
                            <div class="header__burger" @click="toggleMenu">
                                <span></span>
                                <span></span>
                                <span class="burger-little-bar"></span>
                            </div>
                            <nav class="header__menu" :class="{ active: isActive }">
                                <ul class='header__list'>
                                    <li @click="toggleMenu">
                                        <a href="/" class="header__link">О нас</a>
                                    </li>
                                    <li @click="toggleMenu">
                                        <a href='/hotels' class="header__link">Отели и номера</a>
                                    </li>
                                    <li @click="toggleMenu">
                                        <a href="#contacts" class="header__link">Контакты</a>
                                    </li>
                                    <li onclick="alert('Скоро появится на сайте!')">
                                        <a href="#" class="header__link">Авиабилеты</a>
                                    </li>
                                    <li @click="toggleMenu">
                                        <a class="user-logo" href="/#lk_choose">
                                            <img src="../assets/img/person_icon.png" alt="">
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-logo">
                <a href="/#lk_choose">
                    <img src="../assets/img/person_icon.png" alt="">
                </a>
            </div>
        </div>
    </div>
    <div class="header-menu-user"
        v-if="$route.path !== '/'">
        <div class="menu-components">
            <div class="company-logo">
                <a href="/"><img src="../assets/img/company-logo-grey.png" alt=""></a>
            </div>
            <div class="header-anchors">
                <a href="/">О нас</a>
                <a href='/hotels'>Отели и номера</a>
                <a href="#contacts">Контакты</a>
                <a href="#" onclick="alert('Скоро появится на сайте!')">Авиабилеты</a>
            </div>
            <div class="wrapper">
                <div class="head">
                    <div class="container">
                        <div class="header__body">
                            <div class="header__logo">
                                <a href="/"><img src="../assets/img/company-logo-grey.png" alt=""></a>
                            </div>
                            <div class="header__burger" @click="toggleMenu">
                                <span></span>
                                <span></span>
                                <span class="burger-little-bar"></span>
                            </div>
                            <nav class="header__menu" :class="{ active: isActive }">
                                <ul class='header__list'>
                                    <li @click="toggleMenu">
                                        <a href="/" class="header__link">О нас</a>
                                    </li>
                                    <li @click="toggleMenu">
                                        <a href='/hotels' class="header__link">Отели и номера</a>
                                    </li>
                                    <li @click="toggleMenu">
                                        <a href="#contacts" class="header__link">Контакты</a>
                                    </li>
                                    <li onclick="alert('Скоро появится на сайте!')">
                                        <a href="#" class="header__link">Авиабилеты</a>
                                    </li>
                                    <li @click="toggleMenu">
                                        <a class="user-logo" href="/#lk_choose">
                                            <img src="../assets/img/person_icon.png" alt="">
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-logo">
                <a href="/#lk_choose">
                    <img src="../assets/img/person_icon.png" alt="">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isActive: false,
        }
    },
    methods: {
        toggleMenu() {
            this.isActive = !this.isActive;
        },
        openWebsite() {
            this.$router.push('/lk/').then(() => {
                window.scrollTo(0, 0);
            });
        }
    }
}
</script>