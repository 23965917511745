<template>
  <div class="modal fade" :id="`booking_${room.id}`" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Забронировать {{ room.title }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form class="booking-block" id="booking-form">
          <p>Заезд: {{ new Date(filter_data.start).toLocaleString("ru", options = {year: 'numeric',month: 'long',day: 'numeric'}) }}</p>
          <p>Выезд: {{ new Date(filter_data.end).toLocaleString("ru", options = {year: 'numeric',month: 'long',day: 'numeric',}) }}</p>

          <select class="form-select mt-3" v-model="filter_data.guests" disabled>
            <option value="0" selected disabled>Количество гостей</option>
            <option value="1">1 гость</option>
            <option value="2">2 гостя</option>
            <option value="3">3 гостя</option>
            <option value="4">4 гостя</option>
            <option value="5">5+ гостей</option>
          </select>

            <div class="form-floating">
              <input type="text" class="form-control mt-3" id="name-booking" required v-model="filter_data.name">
              <label for="name-booking">Имя</label>
            </div>

            <div class="form-floating">
              <input type="text" class="form-control mt-3" id="surname-booking" required v-model="filter_data.surname">
              <label for="surname-booking">Фамилия</label>
            </div>

            <div class="form-floating">
              <input type="email" class="form-control mt-3" id="mail-booking" required v-model="filter_data.mail">
              <label for="mail-booking">E-mail</label>
            </div>

            <div class="form-floating">
              <input type="tel" class="form-control mt-3" id="phone-booking" placeholder="+7(___)___-__-__" required v-model="filter_data.phone">
              <label for="phone-booking">Номер телефона</label>
            </div>
            
            

            <div class="form-floating">
              <input type="text" class="form-control mt-3" id="licence-booking" required v-model="filter_data.licence">
              <label for="licence-booking">Серия, номер паспорта</label>
            </div>

            
        </form>
      </div>
      <div class="modal-footer">
        <div class="hotel-booking-button-modal">
          <a @click="sendData()">Забронировать</a>
        </div>
        
      </div>
    </div>
  </div>
</div>

    <div class="possible-number-container">
        <div class="possible-number-block">
            <div class="possible-number-image">
                <div :id="`rooms_img_carousel_${room.id}`" class="carousel slide carousel_room_img" data-bs-ride="false">
                    <div class="carousel-indicators">
                    <button type="button" :data-bs-target="`#rooms_img_carousel_${room.id}`" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" v-for="(img, index) in room.img.filter((x)=>x!==room.img[0])" :key="index" :data-bs-target="`#rooms_img_carousel_${room.id}`" :data-bs-slide-to="index+1" aria-current="true" aria-label="Slide 1"></button>
                    </div>
                    <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img :src="`https://admin.moshot.ru/api/images_mini_rooms/${room.id}/${room.img[0]}`" alt="Room Image">
                    </div>
                    <div class="carousel-item" v-for="(img, index) in room.img.filter((x)=>x!==room.img[0])" :key="index">
                        <img :src="`https://admin.moshot.ru/api/images_mini_rooms/${room.id}/${img}`" alt="Room Image">
                    </div>
                    </div>
                    <button class="carousel-control-prev" type="button" :data-bs-target="`#rooms_img_carousel_${room.id}`" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" :data-bs-target="`#rooms_img_carousel_${room.id}`" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            <div class="possible-number-info">
                <div class="possible-number-name">
                    <p> {{ room.title }} </p>
                    <p class="possible-number-beds"> {{ room.bed }} </p>
                </div>
                <div class="possible-number-service">
                    <div class="service">
                        <img src="../assets/img/card.png" alt="">
                        <p>Оплата картой на сайте</p>
                    </div>
                    <div class="service">
                        <img src="../assets/img/eat.png" alt="">
                        <p>Завтрак <span v-if="!room.services.includes('Завтрак включен')"> не </span> включен </p>
                    </div>
                    <div class="service">
                        <img src="../assets/img/wifi.png" alt="">
                        <p><span v-if="room.services.includes('Wi-Fi')"> Бесплатный </span> <span v-else> Не бесплатный </span> Wi-Fi</p>
                    </div>
                </div>
                <button class="button-number-details visible btn btn-primary" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#details_' + room.id" aria-expanded="false" aria-controls="collapseExample"
                    @click="toggleRotateNumber">
                    <p>Подробнее о номере</p>
                    <img src="../assets/img/number-details.png" alt="" :class="{ 'rotate': isRotateNumber }">
                </button>
            </div>
            <div class="possible-number-price">
                <p class="price"> {{ room.price*nights }} ₽</p>
                <p> {{ nights }} <span v-if="nights == 1">ночь</span><span v-if="nights < 5 && nights !== 1">ночи</span><span v-if="nights >= 5">ночей</span>
                    <span v-if="guests > 0">, {{ guests }} <span v-if="guests == 1">гость</span> <span v-if="guests < 5 && guests > 1">гостя</span><span v-if="guests >= 5">гостей</span></span></p>
                    <a type="button" data-bs-toggle="modal" :data-bs-target="`#booking_${room.id}`">Забронировать</a>
            </div>
            <button class="button-number-details hidden btn btn-primary" type="button" data-bs-toggle="collapse"
                :data-bs-target="'#details_' + room.id" aria-expanded="false" aria-controls="collapseExample"
                @click="toggleRotateNumber">
                <p>Подробнее о номере</p>
                <img src="../assets/img/number-details.png" alt="" :class="{ 'rotate': isRotateNumber }">
            </button>
        </div>
        <div class="collapse" :id="'details_' + room.id">
            <div class="card card-body">
                <div class="possible-number-details">
                    <div class="life-rules">
                        <p>Правила проживания:</p>
                        <div class="number-timings">
                            <div class="number-check-in">
                                <p class="timing-name">Заезд</p>
                                <p>после {{ room.in_time }} </p>
                            </div>
                            <div class="number-exit">
                                <p class="timing-name">Отъезд</p>
                                <p>до {{ room.out_time }} </p>
                            </div>
                            <div class="number-minimum-stay">
                                <p class="timing-name">Минимальный срок проживания</p>
                                <p>{{ room.min_time }}</p>
                            </div>
                        </div>
                        <div class="number-basic-rules">
                            <div class="basic-rule">
                                <img src="../assets/img/baby.png" alt="">
                                <p><span v-if="kidsAviability === true">можно</span> <span
                                        v-if="kidsAviability === false">нельзя</span> с детьми</p>
                            </div>
                            <div class="basic-rule">
                                <img src="../assets/img/no-smoking.png" alt="">
                                <p>курить <span v-if="smokingAviability === true"> разрешено </span> <span v-if="smokingAviability === false"> запрещено </span> </p>
                            </div>
                            <div class="basic-rule">
                                <img src="../assets/img/pets.png" alt="">
                                <p><span v-if="petsAviability === true">можно</span> <span
                                        v-if="petsAviability === false">нельзя</span> с питомцами <span
                                        v-if="petsAviability === true"> {{
                                            howWithPets
                                        }} </span></p>
                            </div>
                            <div class="basic-rule">
                                <img src="../assets/img/document.png" alt="">
                                <p>владелец <span v-if="documentsGive === true">предоставляет</span> <span
                                        v-if="documentsGive === false">не предоставляет</span> отчетные документы о
                                    проживании</p>
                            </div>
                        </div>
                    </div>
                    <!--<div class="whats-next">
                        <p>Что рядом?</p>
                        <div class="point">
                            <div class="point-name">
                                <p>{{ firstPoint }}</p>
                                <a href="">Посмотреть на карте</a>
                            </div>
                            <div class="point-range">
                                <p> {{ firstPointRange }} км</p>
                            </div>
                        </div>
                        <div class="point">
                            <div class="point-name">
                                <p>{{ secondPoint }}</p>
                                <a href="">Посмотреть на карте</a>
                            </div>
                            <div class="point-range">
                                <p> {{ secondPointRange }} км</p>
                            </div>
                        </div>
                        <div class="point">
                            <div class="point-name">
                                <p> {{ thirdPoint }} </p>
                                <a href="">Посмотреть на карте</a>
                            </div>
                            <div class="point-range">
                                <p>{{ thirdPointRange }} км</p>
                            </div>
                        </div>
                        <button class="all-points">
                            Все места
                        </button>
                    </div>-->
                    <div class="basic-amenities">
                        <p>Основные удобства</p>
                        <div class="basic-amenities-list">
                            <div class="main-convenience" v-if="room.services.includes('Wi-Fi')">
                                <img src="../assets/img/wifi-blue.png" alt="">
                                <p>Wi-Fi</p>
                            </div>
                            <div class="main-convenience" v-if="room.services.includes('Автостоянка/парковка')">
                                <img src="../assets/img/parking-blue.png" alt="">
                                <p>Автостоянка/парковка</p>
                            </div>
                            <div class="main-convenience" v-if="room.services.includes('Сауна')">
                                <img src="../assets/img/sauna-blue.png" alt="">
                                <p>Сауна</p>
                            </div>
                            <div class="main-convenience" v-if="room.services.includes('Фен')">
                                <img src="../assets/img/hairdryer-blue.png" alt="">
                                <p>Фен</p>
                            </div>
                            <div class="main-convenience" v-if="room.services.includes('Бар/ресторан')">
                                <img src="../assets/img/tableware-blue.png" alt="">
                                <p>Бар/ресторан</p>
                            </div>
                        </div>
                        <button class="btn btn-primary" type="button" data-bs-toggle="collapse"
                            :data-bs-target="'#conveniences_' + id" aria-expanded="false"
                            aria-controls="collapseExample" @click="toggleRotateUsability">
                            <p>Посмотреть все удобства</p>
                            <img src="../assets/img/number-details.png" alt="" :class="{ 'rotate': isRotateUsability }">
                        </button>
                        <div class="collapse" :id="'conveniences_' + id">
                            <div class="card card-body">
                                <div class="conveniences">
                                    <div class="conveniences-block-left">
                                        <p>Ванная комната</p>
                                        <div class="bathroom">
                                            <p v-for="(item, index) in bathroom" :class="{'d-none': !room.services.includes(item)}" :key="index">{{ item }}</p>
                                        </div>
                                    </div>
                                    <div class="conveniences-block-right">
                                        <div class="kitchen">
                                            <p>Кухонное оборудование</p>
                                            <div class="kitchen-equipment">
                                                <p v-for="(item, index) in kitchenEquipment" :class="{'d-none': !room.services.includes(item)}" :key="index">{{ item }}</p>
                                            </div>
                                        </div>
                                        <div class="yard">
                                            <p>Оснащение двора</p>
                                            <div class="yard-equipment">
                                                <p v-for="(item, index) in yardEquipment" :class="{'d-none': !room.services.includes(item)}" :key="index">{{ item }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    data() {
        return {
            isRotateNumber: false,
            isRotateUsability: false,
            kidsAviability: true,
            smokingAviability: false,
            petsAviability: true,
            howWithPets: 'по согласованию с хозяином жилья',
            documentsGive: true,
            firstPoint: 'Мариинский театр',
            firstPointRange: 2.3,
            secondPoint: 'Новая Голландия',
            secondPointRange: 1.7,
            thirdPoint: 'Юсуповский дворец',
            thirdPointRange: 3.4,
            bathroom: [
                'Полотенца',
                'Туалетные принадлежности',
                'Душ',
                'Халат',
                'Тапочки',
                'Гигиенический душ',
                'Сауна',
                'Фен'
            ],
            kitchenEquipment: ['Электрический чайник', 'Холодильник'],
            yardEquipment: ['Парковка', 'Веранда', 'Терраса', 'Охраняемая территория', 'Обеденная зона на улице'],
            filter_data: {
                guests: this.$props.guests,
                room_id: this.$props.room.id,
                start: this.$props.start,
                end: this.$props.end
            }
        };
    },
    props: {
        room: Object,
        nights: Number,
        guests: Number,
        start: String,
        end: String
    },
    methods: {
        // getImageSrc(image) {
        //     try {
        //         return require(`@/assets/img/${image}`);
        //     } catch (error) {
        //         throw new Error(error);
        //     }
        // },
        toggleRotateNumber() {
            this.isRotateNumber = !this.isRotateNumber;
        },
        toggleRotateUsability() {
            this.isRotateUsability = !this.isRotateUsability;
        },

        sendData(){
        // this.filter_data.start =  $('.js-daterangepicker-booking').data('daterangepicker').startDate.format('YYYY-MM-DD')
        // this.filter_data.end =  $('.js-daterangepicker-booking').data('daterangepicker').endDate.format('YYYY-MM-DD')

        axios.post('/api/create_booking.php', this.filter_data).then((response)=>{
            console.log(response.data)
            alert('Номер забронирован!')
            window.location.reload()
        })
        }
    },

    watch: {
        start:{
        handler(newVal){
            this.filter_data.start = newVal
        },
        deep: true
        },
        end:{
        handler(newVal){
            this.filter_data.end = newVal
            console.log(newVal)
        },
        deep: true
        },
        guests:{
        handler(newVal){
            this.filter_data.guests = newVal
        },
        deep: true
        },
    },

    mounted(){
        IMask(document.getElementById('phone-booking'), {
            mask: '+{7}(000)000-00-00'
        });
    }
}
</script>