<template>
  <div class="container-reservations">
    <div class="action-buttons">
      <button :class="{ 'active-button': activeActionButton === 'reservations' }"
        @click="setActiveActionButton('reservations')">Бронирования</button>
      <button :class="{ 'active-button': activeActionButton === 'favourites' }"
        @click="setActiveActionButton('favourites')">Избранное</button>
      <button :class="{ 'active-button': activeActionButton === 'bonuses' }"
        @click="setActiveActionButton('bonuses')">Бонусы</button>
      <button :class="{ 'active-button': activeActionButton === 'settings' }"
        @click="setActiveActionButton('settings')">Настройки</button>
    </div>
    <div class="discount-system" v-if="activeActionButton === 'reservations'">
      <div class="discounts">
        <p class="discounts-heading">Накопительная система скидок!</p>
        <p>после первого бронирования скидка 2%</p>
        <p>после второго – скидка 3%</p>
        <p>после пятого – скидка 4%</p>
        <p>после десятого – скидка 5%</p>
      </div>
      <div class="present">
        <img src="../assets/img/present.png" alt="">
      </div>
    </div>
    <div class="reserved-hotels" v-if="activeActionButton === 'reservations'">
      <div class="reservations-buttons">
        <button :class="{ 'active-button': activeReservationButton === 'all' }"
          @click="setActiveReservationButton('all')">Все</button>
        <button :class="{ 'active-button': activeReservationButton === 'reserved' }"
          @click="setActiveReservationButton('reserved')">Действительные</button>
        <button :class="{ 'active-button': activeReservationButton === 'archive' }"
          @click="setActiveReservationButton('archive')">В архиве</button>
        <button :class="{ 'active-button': activeReservationButton === 'canceled' }"
          @click="setActiveReservationButton('canceled')">Отмененные</button>
      </div>
      <div class="reservations-hotels">
        <ReservationHotel v-for="(hotel, index) in filteredHotels" :key="index" :reserved="hotel.reserved"
          :image="hotel.image" :name="hotel.name" :beds="hotel.beds" :canPay="hotel.canPay"
          :eatAvailability="hotel.eatAvailability" :wifiAviability="hotel.wifiAviability" :price="hotel.price"
          :nights="hotel.nights" :guests="hotel.guests" :dateCheckIn="hotel.dateCheckIn"
          :dateCheckOut="hotel.dateCheckOut" />
      </div>
    </div>
    <div class="container-bonuses" v-if="activeActionButton === 'bonuses'">
      <div class="main-block">
        <div class="stats-block">
          <p>Статистика</p>
          <div class="stats" v-bind:data-orders="orders" v-bind:data-discount="discount">
            <div class="stat-order">
              <p>Всего заказов</p>
              <p class="orders"> {{ orders }} </p>
            </div>
            <div class="stat-discount">
              <p>Скидка</p>
              <p class="discount">{{ discount }} %</p>
            </div>
          </div>
        </div>
        <div class="programm-block">
          <p>Описание программы</p>
          <div class="programm-bonuses">
            <p>после <span>первого </span>бронирования скидка 2%</p>
            <p>после <span>второго </span>– скидка 3%</p>
            <p>после <span>пятого </span>– скидка 4%</p>
            <p>после <span>десятого </span>– скидка 5%</p>
            <p class="discount-text">Скидка применяется автоматически при каждом новом бронировании</p>
          </div>
        </div>
      </div>
      <div class="discount-details">
        <p>Детализация активации скидок</p>
        <div class="details">
          <div class="detail" v-for="(detail, index) in details" :key="index">
            <div class="date">
              <p>{{ detail.date }}</p>
            </div>
            <div class="discount">
              <p>{{ detail.discount }}%</p>
            </div>
            <div class="for-what">
              <p> {{ detail.forWhat }} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-settings" v-if="activeActionButton === 'settings'">
      <div class="settings-block">
        <div class="about-you-block">
          <p>О вас</p>
          <div class="about-you-settings">
            <div class="your-name">
              <p>Имя: </p>
              <input type="text" name="" id="" placeholder="Имя" required class="input-field">
            </div>
            <div class="your-surname">
              <p>Фамилия: </p>
              <input type="text" name="email" placeholder="Фамилия" required class="input-field">
            </div>
            <div class="your-mail">
              <p>Электронный адрес: </p>
              <input type="email" id="email" name="email" placeholder="e-mail" required class="input-field">
            </div>
            <div class="your-country">
              <p>Страна: </p>
              <div class="country-input">
                <input type="text" name="" id="" placeholder="Не задана" required class="input-field">
                <button><img src="../assets/img/settings-arrow.png" alt=""></button>
              </div>
            </div>
            <div class="your-city">
              <p>Город: </p>
              <input type="text" name="" id="" placeholder="Город" required class="input-field">
            </div>
            <div class="your-birthday">
              <p>Дата рождения:</p>
              <div class="birthday-buttons">
                <button>День<img src="../assets/img/settings-arrow.png" alt=""></button>
                <button>Месяц<img src="../assets/img/settings-arrow.png" alt=""></button>
                <button style="margin-right: 0px;">Год<img src="../assets/img/settings-arrow.png" alt=""></button>
              </div>
            </div>
            <div class="your-gender">
              <p>Пол: </p>
              <div class="gender-input">
                <button>Предпочитаю не указывать <img src="../assets/img/settings-arrow.png" alt=""></button>
              </div>
            </div>
            <button class="save-settings">Сохранить</button>
          </div>
        </div>
        <div class="password-block">
          <p>Изменить пароль</p>
          <div class="password">
            <input type="text" name="" id="main-password" placeholder="Текущий пароль" required class="input-field">
            <input type="text" name="" id="new-password" placeholder="Новый пароль" required class="input-field">
            <input type="text" name="" id="repeat-new-password" placeholder="Новый пароль еще раз" required
              class="input-field">
            <button>Изменить пароль</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReservationHotel from '@/components/ReservationHotel.vue';

export default {
  components: {
    ReservationHotel,
  },
  data() {
    return {
      details: [
        {
          date: '07.08.2024',
          discount: 2,
          forWhat: 'Бронирование отеля'
        },
        {
          date: '09.09.2024',
          discount: 3,
          forWhat: 'Бронирование отеля'
        },
        {
          date: '15.09.2024',
          discount: 3,
          forWhat: 'Бронирование отеля'
        }
      ],
      orders: 3,
      discount: 3,
      ReservationHotels: [
        {
          reserved: true,
          image: 'art-standart-first.png',
          name: 'ART стандарт',
          beds: 1,
          canPay: 'на сайте',
          eatAvailability: true,
          wifiAviability: true,
          price: '8 012 ₽',
          nights: 1,
          guests: 1,
          dateCheckIn: '07.08.2024',
          dateCheckOut: '08.08.2024',
        },
        {
          reserved: false,
          image: 'art-standart-second.png',
          name: 'ART стандарт',
          beds: 1,
          canPay: 'на сайте',
          eatAvailability: true,
          wifiAviability: true,
          price: '16 024 ₽',
          nights: 2,
          guests: 1,
          dateCheckIn: '15.07.2024',
          dateCheckOut: '17.07.2024',
        },
        {
          reserved: null,
          image: 'art-standart-third.png',
          name: 'ART стандарт',
          beds: 1,
          canPay: 'на сайте',
          eatAvailability: true,
          wifiAviability: true,
          price: '16 024 ₽',
          nights: 2,
          guests: 1,
          dateCheckIn: '30.08.2024',
          dateCheckOut: '02.09.2024',
        },
      ],
      filteredHotels: [],
      activeReservationButton: 'all',
      activeActionButton: 'reservations',
    }
  },
  mounted() {
    this.showAll(); // Показать все отели по умолчанию
  },
  methods: {
    showAll() {
      this.filteredHotels = this.ReservationHotels;
    },
    showReserved(reserved) {
      this.filteredHotels = this.ReservationHotels.filter(hotel => {
        if (reserved === null) {
          return hotel.reserved === null; // В архиве
        } else {
          return hotel.reserved === reserved; // Действительные или Отмененные
        }
      });
    },
    setActiveReservationButton(button) {
      this.activeReservationButton = button; // Устанавливаем новую активную кнопку

      // Вызываем соответствующий метод фильтрации
      if (button === 'all') {
        this.showAll();
      } else if (button === 'reserved') {
        this.showReserved(true);
      } else if (button === 'archive') {
        this.showReserved(null);
      } else if (button === 'canceled') {
        this.showReserved(false);
      }
    },
    setActiveActionButton(button) {
      this.activeActionButton = button; // Устанавливаем новую активную кнопку
    },
  },
}
</script>