<template>
    <footer id="contacts">
        <div class="footer">
            <div class="footer-info">
                <div class="footer-logo">
                    <a href="/"><img src="../assets/img/company-logo.png" alt=""></a>
                    <p>Московский Отельер</p>
                    <p>© 2024</p>
                </div>
                <div class="footer-anchors">
                    <a href='/hotels'>Отели</a>
                    <a href='/lk/'>Акции</a>
                    <a href="/">О нас</a>
                    <a href="">Личный кабинет</a>
                    <a href='/lk/'>Система скидок</a>
                    <a href="">Поддержка</a>
                </div>
                <div class="footer-description">
                    <p class="footer-description-heading">Ваш надежный партнер в бронировании отелей в Москве</p>
                    <p>Мы предлагаем удобный и быстрый сервис для бронирования отелей в Москве. Наша компания гарантирует
                        лучшие цены, прозрачные условия и круглосуточную поддержку. Доверьтесь нам, и ваше пребывание в
                        Москве будет комфортным и приятным!</p>
                </div>
            </div>
            <div class="footer-political-block">
                <div class="footer-political">
                    <a href="">Политика конфиденциальности</a>
                    <a href="">Договор офферты</a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>