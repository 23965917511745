<template>
  <HeaderMenuMain/>
  <router-view/>
  <FooterMain/>
</template>

<script>
import HeaderMenuMain from './components/HeaderMenuMain.vue';
import FooterMain from './components/FooterMain.vue';
export default {
  components: {
    HeaderMenuMain,
    FooterMain
  },
}
</script>

<style lang="scss" src="../src/assets/style/style.scss">
  //@import url('../src/assets/style/style.scss');
</style>