<template>
    <div class="container-choosing">
        <div class="choosed-hotel">
            <div class="hotel-info">
                <div class="hotel-description">
                    <div class="stars">
                        <img src="../assets/img/star.png" alt="" v-for="star in Number(hotel_info.stars)" :key="star">
                    </div>
                    <div class="hotel-name">
                        <p>{{ hotel_info.title }}</p>
                    </div>
                    <div class="hotel-address">
                        <p>{{ hotel_info.address }}</p>
                    </div>
                </div>
                <div class="hotel-rate">
                    <!--<p>{{ hotel_info.reviews_count }} отзывов</p>
                    <p class="rate">{{ hotel_info.map_score }}</p>-->
                </div>
            </div>
            <div class="hotel-images">
                <div class="hotel-selected-image">
                    <button class="hotel-prev-button" @click="prevImage">
                        <img src="../assets/img/pages-swiper.png" alt="">
                    </button>
                    <button class="hotel-next-button" @click="nextImage">
                        <img src="../assets/img/pages-swiper.png" alt="">
                    </button>
                    <div class="hotel-number-slide">
                        <p>{{ currentImageIndex + 1 }}/{{ hotel_info.img.length }}</p>
                    </div>
                    <div class="selected-image" :style="{ display: currentImageIndex === index ? 'none' : 'block' }">
                        <img :src="`https://admin.moshot.ru/api/images_hotels/${hotel_info.id}/${hotel_info.img[currentImageIndex]}`" alt="">
                    </div>
                </div>
                <div class="hotel-slides">
                    <div class="hotel-slides-images">
                        <button v-for="(image, index) in hotel_info.img" :key="index" @click="selectImage(index)">
                            <img :src="`https://admin.moshot.ru/api/images_mini_hotels/${hotel_info.id}/${image}`" alt="">
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="filter" style="z-index: 2;">
            <!--<div class="filter-check-in">
                <input type="text" placeholder="18 августа" class="">
                <button>
                    <img src="../assets/img/filter-calendar.png" alt="">
                </button>
            </div>
            <div class="filter-exit">
                <input type="text" placeholder="20 августа" class="">
                <button>
                    <img src="../assets/img/filter-calendar.png" alt="">
                </button>
            </div>-->

            <div class="filter-check-in">
                <div class="input-group">
                <input type="text" class="form-control pageFilter__daterange js-daterangepicker" id="period_a0acfa46" autocomplete="off"><span class="input-group-text" style="border: 0;"><label for="period_a0acfa46"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
                <input type="hidden" name="filter[start_period]" class="js-daterangepicker__start" value="10.02.2024">
                <input type="hidden" name="filter[end_period]" class="js-daterangepicker__end" value="10.03.2024">
            </div>
            

            <!--<div class="filter-tickets">
                <input type="text" placeholder="1 человек (взрослый)" class="">
                <button>
                    <img src="../assets/img/filter-arrow.png" alt="">
                </button>
            </div>-->

            <details class="custom-select filter-tickets" id="guestsSelect">
                <summary class="radios">
                    <input type="radio" name="item" id="default_guest" title="Количество гостей" value="0" checked v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                    <input type="radio" name="item" id="guest1" title="1 гость" value="1" v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                    <input type="radio" name="item" id="guest2" title="2 гостя" value="2" v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                    <input type="radio" name="item" id="guest3" title="3 гостя" value="3" v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                    <input type="radio" name="item" id="guest4" title="4 гостя" value="4" v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                    <input type="radio" name="item" id="guest5" title="5+ гостей" value="5" v-model="filter_data.guests" onclick="document.querySelector('#guestsSelect').open = !document.querySelector('#guestsSelect').open">
                </summary>
                <ul class="list">
                    <li>
                        <label for="guest1">
                            1 гость
                            <span></span>
                        </label>
                    </li>
                    <li>
                        <label for="guest2">2 гостя</label>
                    </li>
                    <li>
                        <label for="guest3">3 гостя</label>
                    </li>
                    <li>
                        <label for="guest4">4 гостя</label>
                    </li>
                    <li>
                        <label for="guest5">5+ гостей</label>
                    </li>
                </ul>
            </details>

            <button @click="applyFilter()">Найти</button>
        </div>
        <div class="possible-numbers">
            <p>Возможные номера</p>
            <div class="possible-numbers-wrapper">
                <HotelRoom v-for="room in rooms" :key="room.id" :room="room" :nights="nights_card" :guests="guests_card" :start="filter_data.start" :end="filter_data.end"/>
            </div>
        </div>
        <div class="about-hotel-container">
            <div class="about-hotel">
                <div class="hotel-info">
                    <div class="hotel-name">
                        <p>Об отеле {{ hotel_info.title }}</p>
                    </div>
                    <!--<div class="hotel-count-and-date">
                        <p>{{ hotel_info.rooms_count }} номеров</p>
                    </div>-->
                </div>
                <!--<div class="hotel-images">
                    <div class="hotel-main-image">
                        <img src="../assets/img/about-hotel.png" alt="">
                    </div>
                    <div class="hotel-another-images">
                        <img src="../assets/img/about-hotel-another-1.png" alt="">
                        <img src="../assets/img/about-hotel-another-2.png" alt="">
                    </div>
                </div>-->
                <div class="hotel-description">
                    <!--<p>Описание</p>-->
                    <p class="about-hotel-text" style="white-space: pre;">{{ hotel_info.description }}</p>
                </div>
            </div>
        </div>
        <div class="location-container">
            <div class="location">
                <p class="location-text">Местоположение на карте</p>
                <p class="location-address">{{ hotel_info.address }}</p>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d2396.889919547388!2d37.623533138211855!3d55.757803754581666!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2sru!4v1723597973860!5m2!1sru!2sru"
                    width="100%" height="406" style="border:0;" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import HotelRoom from '@/components/HotelRoom.vue';
import axios from 'axios';

export default {
    props: {
        hotel_id: Number
    },
    components: {
        HotelRoom,
    },
    data() {
        return {
            PossibleHotels: [
                {
                    id: 1,
                    image: 'art-standart-first.png',
                    name: 'ART комфорт',
                    beds: 1,
                    canPay: 'на сайте',
                    eatAvailability: true,
                    wifiAviability: true,
                    price: '16 024 ₽',
                    nights: 2,
                    guests: 1,
                    checkIn: '14:00',
                    checkOut: '12:00',
                    howManyDays: 1,
                    kidsAviability: true,
                    smokingAviability: false,
                    petsAviability: true,
                    howWithPets: 'по согласованию с хозяином жилья',
                    documentsGive: true,
                    firstPoint: 'Мариинский театр',
                    firstPointRange: 2.3,
                    secondPoint: 'Новая Голландия',
                    secondPointRange: 1.7,
                    thirdPoint: 'Юсуповский дворец',
                    thirdPointRange: 3.4,
                    WiFiExist: true,
                    ParkingExist: true,
                    SaunaExist: true,
                    HairdryerExist: true,
                    BarOrRestaurantExist: true,
                    bathroom: ['полотенца', 'туалетные принадлежности', 'фен', 'душ', 'халат', 'тапочки', 'гигиенический душ'],
                    kitchenEquipment: ['Электрический чайник', 'холодильник'],
                    yardEquipment: ['парковка', 'веранда', 'терраса', 'охраняемая территория', 'обеденная зона на улице']
                },
                {
                    id: 2,
                    image: 'art-standart-second.png',
                    name: 'ART комфорт',
                    beds: 1,
                    canPay: 'на сайте',
                    eatAvailability: true,
                    wifiAviability: true,
                    price: '12 021 ₽',
                    nights: 2,
                    guests: 1,
                    checkIn: '14:00',
                    checkOut: '12:00',
                    howManyDays: 1,
                    kidsAviability: true,
                    smokingAviability: false,
                    petsAviability: true,
                    howWithPets: 'по согласованию с хозяином жилья',
                    documentsGive: true,
                    firstPoint: 'Мариинский театр',
                    firstPointRange: 2.3,
                    secondPoint: 'Новая Голландия',
                    secondPointRange: 1.7,
                    thirdPoint: 'Юсуповский дворец',
                    thirdPointRange: 3.4,
                    WiFiExist: true,
                    ParkingExist: true,
                    SaunaExist: true,
                    HairdryerExist: true,
                    BarOrRestaurantExist: true,
                    bathroom: ['полотенца', 'туалетные принадлежности', 'фен', 'душ', 'халат', 'тапочки', 'гигиенический душ'],
                    kitchenEquipment: ['Электрический чайник', 'холодильник'],
                    yardEquipment: ['парковка', 'веранда', 'терраса', 'охраняемая территория', 'обеденная зона на улице']
                },
                {
                    id: 3,
                    image: 'art-standart-third.png',
                    name: 'ART комфорт',
                    beds: 1,
                    canPay: 'на сайте',
                    eatAvailability: true,
                    wifiAviability: true,
                    price: '10 521 ₽',
                    nights: 2,
                    guests: 1,
                    checkIn: '14:00',
                    checkOut: '12:00',
                    howManyDays: 1,
                    kidsAviability: true,
                    smokingAviability: false,
                    petsAviability: true,
                    howWithPets: 'по согласованию с хозяином жилья',
                    documentsGive: true,
                    firstPoint: 'Мариинский театр',
                    firstPointRange: 2.3,
                    secondPoint: 'Новая Голландия',
                    secondPointRange: 1.7,
                    thirdPoint: 'Юсуповский дворец',
                    thirdPointRange: 3.4,
                    WiFiExist: true,
                    ParkingExist: true,
                    SaunaExist: true,
                    HairdryerExist: true,
                    BarOrRestaurantExist: true,
                    bathroom: ['полотенца', 'туалетные принадлежности', 'фен', 'душ', 'халат', 'тапочки', 'гигиенический душ'],
                    kitchenEquipment: ['Электрический чайник', 'холодильник'],
                    yardEquipment: ['парковка', 'веранда', 'терраса', 'охраняемая территория', 'обеденная зона на улице']
                },

            ],
            images: [
                require('../assets/img/four-seasons-first.png'),
                require('../assets/img/slide-image-2.png'),
                require('../assets/img/slide-image-3.png'),
                require('../assets/img/slide-image-4.png'),
                require('../assets/img/slide-image-5.png'),
                require('../assets/img/slide-image-6.png'),
            ],
            currentImageIndex: 0,
            isSliding: false,
            startX: 0,
            currentOffset: 0,
            maxScroll: 0,
            hotel_info: {
                img: [],
                stars: 0
            },
            rooms: [],
            filter_data: {
                guests: "0"
            },
            guests_card: "1",
            nights_card: "7"
        }
    },
    methods: {
        selectImage(index) {
            this.currentImageIndex = index;
        },

        prevImage() {
            this.currentImageIndex = (this.currentImageIndex - 1 + this.hotel_info.img.length) % this.hotel_info.img.length;
        },

        nextImage() {
            this.currentImageIndex = (this.currentImageIndex + 1) % this.hotel_info.img.length;
        },

        async loadData(){
            let response = await axios.get(`/api/get_hotel.php?hotel_id=${this.$props.hotel_id}&guests=${this.filter_data.guests}&start=${this.filter_data.start}&end=${this.filter_data.end}`)
                this.hotel_info = response.data[0][0]
                response.data[1].forEach(elem => {
                    elem.services = JSON.parse(elem.services)
                });
                this.rooms = response.data[1]
                console.log(this.rooms)
                this.nights_card = Number((new Date(this.filter_data.end) - new Date(this.filter_data.start)) / (1000 * 60 * 60 * 24));
                this.guests_card = Number(this.filter_data.guests)
        },

        applyFilter(){
            this.filter_data.end = $('.js-daterangepicker').data('daterangepicker').endDate.format('YYYY-MM-DD')
            this.filter_data.start = $('.js-daterangepicker').data('daterangepicker').startDate.format('YYYY-MM-DD')
            this.loadData()
           
        },
    },
    created(){
        this.filter_data.end =  moment().add(10, 'days').format('YYYY-MM-DD');
        this.filter_data.start = moment().add(3, 'days').format('YYYY-MM-DD');
        this.loadData()
    },
    mounted(){
        let end = moment().add(10, 'days');
        let start = moment().add(3, 'days');

        function cb(start, end) {
            $('.js-daterangepicker span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        }

        $('.js-daterangepicker').daterangepicker({
        "locale": {
            "format": "DD.MM.YYYY",
            "separator": " - ",
            "applyLabel": "Сохранить",
            "cancelLabel": "Назад",
            "daysOfWeek": [
                "Вс",
                "Пн",
                "Вт",
                "Ср",
                "Чт",
                "Пт",
                "Сб"
            ],
            "monthNames": [
                "Январь",
                "Февраль",
                "Март",
                "Апрель",
                "Май",
                "Июнь",
                "Июль",
                "Август",
                "Сентябрь",
                "Октябрь",
                "Ноябрь",
                "Декабрь"
            ],
            "firstDay": 1
        },
        
        opens: 'left',
        autoUpdateInput: true,
        startDate: start,
        endDate: end,
        minDate: moment(),
        }, (start, end) => {
            $('.js-daterangepicker').data('daterangepicker').startDate = start
            $('.js-daterangepicker').data('daterangepicker').endDate = end
            this.filter_data.start = start.format('YYYY-MM-DD')
            this.filter_data.end =  end.format('YYYY-MM-DD')
        }, cb);


        cb(start, end);

        $('li[data-range-key="Custom Range"]').text('Произвольно')
    }
}
</script>